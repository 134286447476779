import { LoadingOutlined, LockOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { AlgoliaWaterbodyDetail, GiveawayProduct, SignupParams } from '@omniafishing/core';
import { Checkbox, CheckboxOptionType, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isPending } from '../../constants/loading_state';
import { useRudderstack } from '../../hooks/use_rudderstack';
import { useUserCampaignInfo } from '../../hooks/use_user_campaign_info';
import { useUserPreferences } from '../../hooks/use_user_preferences';
import { getError, getLoadingState, signupUser } from '../../redux/auth';
import { getIpState } from '../../redux/geographic_location';
import { getSpecies } from '../../redux/reference_data';
import { getExperimentUuid } from '../../redux/user';
import AuthFormError from '../auth_form_wrapper/auth_form_error';
import { OmniaButton } from '../omnia_button/omnia_button';
import {
  WaterbodySearchSelect,
  WaterbodySelectValue,
} from '../waterbody_search_select/waterbody_search_select';
import styles from './mini_signup_form.less';

interface MiniSignupFormProps {
  initialEmail: string;
  giveawayProduct: GiveawayProduct;
  onSubmit?: (params: { species: string[]; waterbodies: AlgoliaWaterbodyDetail[] }) => void;
}

const formFields = {
  username: 'username',
  password: 'password',
  species: 'species',
  waterbodies: 'waterbodies',
};

export const MiniSignupForm = (props: MiniSignupFormProps) => {
  const { initialEmail, giveawayProduct, onSubmit } = props;

  const loadingState = useSelector(getLoadingState);
  const error = useSelector(getError);
  const ipState = useSelector(getIpState);
  const species = useSelector(getSpecies);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const experimentUuid = useSelector(getExperimentUuid);
  const { anonymousId } = useRudderstack();
  const { userPreferencesSpecies, userPreferencesWaterbodies } = useUserPreferences();
  const [selectedLakesAsInput, setSelectedLakesAsInput] = useState(
    userPreferencesWaterbodies[0]
      ? userPreferencesWaterbodies.map((w) => {
          return {
            label: <div data-result={w}>{w.primary_name}</div>,
            value: w.id,
          };
        })
      : (undefined as WaterbodySelectValue[])
  );
  const { require_species, require_waterbodies } = giveawayProduct;

  // on signup, the auth token remounts the whole app, so no after-signup action needed

  const userCampaignInfo = useUserCampaignInfo();

  const handleSubmit = (values: any) => {
    const waterbodies = (values[formFields.waterbodies] as WaterbodySelectValue[]) || [];
    const waterbodyIds = waterbodies.map((wsv) => wsv.value);
    const algoliaWaterbodies = waterbodies.map((wsv) => wsv.label.props['data-result']);
    const speciesNames = values[formFields.species];
    const params: SignupParams = {
      username: values[formFields.username],
      password: values[formFields.password],
      organization_registration_code: null,
      ip_state: ipState,
      experiment_group_id: 0,
      experiment_uuid: experimentUuid,
      anonymous_id: anonymousId,
      species: speciesNames,
      waterbodies: waterbodyIds,
      ...userCampaignInfo,
    };

    onSubmit?.({
      species: speciesNames,
      waterbodies: algoliaWaterbodies,
    });
    dispatch(signupUser(params));
  };

  const isSubmitting = isPending(loadingState);

  const speciesOptions: CheckboxOptionType[] = species
    .filter((specie) => specie.recommendations_order != null)
    .sort((a, b) => a.recommendations_order - b.recommendations_order)
    .map((specie) => {
      return {
        label: specie.display_name,
        value: specie.name,
      };
    });

  const defaultSpecies = userPreferencesSpecies.slice(0, 3);
  const defaultWaterbodies = userPreferencesWaterbodies.slice(0, 3);
  const defaultWaterbodySelectValues = defaultWaterbodies.map((w) => {
    return {
      label: <div data-result={w}>{w.primary_name}</div>,
      value: w.id,
    };
  });

  return (
    <>
      <h2>Create an Account to Enter</h2>
      <AuthFormError errorMessage={error} />
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={(e) => {
          console.log(e);
        }}
        form={form}
        initialValues={{
          [formFields.username]: initialEmail,
          [formFields.species]: defaultSpecies,
          [formFields.waterbodies]: defaultWaterbodySelectValues,
        }}
      >
        <Form.Item
          name={formFields.username}
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid email',
            },
            {
              required: true,
              message: 'Please enter your email',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
            type="email"
            size="large"
          />
        </Form.Item>

        <Form.Item
          name={formFields.password}
          rules={[
            {
              required: true,
              message: 'Please enter your password',
            },
            {
              min: 6,
              message: 'Passwords must be 6 characters',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Password"
            type="password"
            size="large"
          />
        </Form.Item>
        {(require_species || require_waterbodies) && (
          <>
            <h1>Tell Us More About Yourself</h1>

            {require_species && (
              <Form.Item
                label="Which species do you fish?"
                name={formFields.species}
                rules={[
                  {
                    required: true,
                    message: 'Please choose species',
                  },
                ]}
              >
                <Checkbox.Group
                  options={speciesOptions}
                  style={{
                    width: '100%',
                    columnCount: 2,
                    textTransform: 'capitalize',
                  }}
                  className={styles.checkboxes}
                />
              </Form.Item>
            )}

            {require_waterbodies && (
              <Form.Item
                label="Where do you fish?"
                name={formFields.waterbodies}
                rules={[
                  {
                    required: true,
                    message: 'Please choose a waterbody',
                  },
                ]}
              >
                <WaterbodySearchSelect
                  placeholder="Ray Roberts Lake"
                  suffixIcon={<SearchOutlined />}
                  showSearch
                  allowClear
                  autoFocus
                  waterbodyLookupValueProp="id"
                  includeUserPrefs={false}
                  value={selectedLakesAsInput}
                  mode="multiple"
                  // if setting initial values to form as we are above,
                  // mode=multiple requires onChange to keep ANT form from bug that changes submission values
                  onChange={(newValues: WaterbodySelectValue[]) => {
                    setSelectedLakesAsInput(
                      newValues.map((w) => {
                        const waterbody = w.label.props['data-result'];
                        const { primary_name, id } = waterbody;
                        return {
                          label: <div data-result={waterbody}>{primary_name}</div>,
                          value: id,
                        };
                      })
                    );
                  }}
                  labelInValue
                />
              </Form.Item>
            )}
          </>
        )}

        <OmniaButton
          kind="secondary"
          size="lg"
          type="submit"
          block
          fontSize={14}
          style={{ marginBottom: 12 }}
          isDisabled={isSubmitting}
        >
          Create Account {isSubmitting ? <LoadingOutlined /> : ''}
        </OmniaButton>
      </Form>
    </>
  );
};
