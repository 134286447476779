import React from 'react';
import { ScrollToTop } from '../components/scroll_to_top/scroll_to_top';
import { SignupGiveawayContainer } from '../components/signup_giveaway/signup_giveaway_container';

const SignupGiveawayPage = (props: { slug: string }) => {
  return (
    <ScrollToTop>
      <SignupGiveawayContainer slug={props.slug} />
    </ScrollToTop>
  );
};
export default SignupGiveawayPage;
