import { LoadingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isPending } from '../../constants/loading_state';
import { useRudderstack } from '../../hooks/use_rudderstack';
import { getError, getLoginLoadingState, loginUser } from '../../redux/auth';
import { RoutePaths } from '../../routes';
import utilities from '../../styles/utilities.less';
import AuthFormError from '../auth_form_wrapper/auth_form_error';
import { OmniaButton } from '../omnia_button/omnia_button';

interface MiniLoginFormProps {
  initialEmail: string;
}

export const MiniLoginForm = (props: MiniLoginFormProps) => {
  const { initialEmail } = props;
  const error = useSelector(getError);
  const loadingState = useSelector(getLoginLoadingState);
  const isSubmitting = isPending(loadingState);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { anonymousId } = useRudderstack();

  const formFields = {
    username: 'username',
    password: 'password',
  };

  const handleSubmit = (values: any) => {
    dispatch(loginUser(values[formFields.username], values[formFields.password], anonymousId));
  };

  return (
    <>
      <AuthFormError errorMessage={error} />

      <h2 className={utilities.textCenter}>
        Looks Like you Already Have an Account. <br />
        Log In to Omnia Fishing
      </h2>

      <Form
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          [formFields.username]: initialEmail,
        }}
        form={form}
        style={{ padding: 20 }}
      >
        <Form.Item
          name={formFields.username}
          rules={[{ required: true, message: 'Please input your email' }]}
        >
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
            type="email"
            size="large"
          />
        </Form.Item>

        <Form.Item
          name={formFields.password}
          rules={[{ required: true, message: 'Please input your password' }]}
        >
          <Input
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
            size="large"
          />
        </Form.Item>

        <OmniaButton
          kind="secondary"
          type="submit"
          size="lg"
          fontSize={14}
          block
          style={{ marginBottom: 12 }}
          isDisabled={isSubmitting}
        >
          Log in {isSubmitting ? <LoadingOutlined /> : ''}
        </OmniaButton>

        <div className={classNames(utilities.textRight)}>
          <Link to={RoutePaths.PASSWORD_RESET}>Forgot password</Link>
        </div>
      </Form>
    </>
  );
};
