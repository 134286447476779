import { GiveawayProduct } from '@omniafishing/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useQueryString } from '../../hooks/use_query_string';
import { getImgixPath } from '../../lib/imgix';
import { hasAccessToken } from '../../redux/auth';
import { getIsMobile } from '../../redux/window';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { SEO } from '../seo/seo';
import { WistiaEmbed } from '../wistia_embed/wistia_embed';
import { CheckEmailForm } from './check_email_form';
import { MiniLoginForm } from './mini_login_form';
import { MiniSignupForm } from './mini_signup_form';
import styles from './signup_giveaway.less';
import { SignupGiveawayForm } from './signup_giveaway_form';

interface SingupGiveawayProps {
  giveaway: GiveawayProduct;
}

export enum SignupGiveawayQueryParams {
  species = 'species',
  waterbody_name = 'waterbody_name',
  waterbody_slug = 'waterbody_slug',
}

export const SignupGiveaway = (props: SingupGiveawayProps) => {
  const isMobile = useSelector(getIsMobile);
  const isLoggedIn = useSelector(hasAccessToken);
  const { replaceQueryString } = useQueryString();
  const [userEmail, setUserEmail] = useState(null as string);
  const [userEmailExists, setUserEmailExists] = useState(null as boolean);
  const { giveaway } = props;

  if (!giveaway) {
    return null;
  }

  const { title, image, description, faq, giveaway_type, rules, video, slug } = giveaway;
  const isDrawing = giveaway_type === 'drawing';
  const isGiveaway = giveaway_type === 'giveaway';

  const hasVideo = video != null;
  const isYoutube = video?.includes('youtube');
  const videoContent = isYoutube ? (
    <div className={styles.videoYoutube}>
      <iframe
        width="560"
        height="315"
        src={video}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  ) : (
    <div className={styles.video}>
      <WistiaEmbed embedUrl={video} />
    </div>
  );

  return (
    <section className={styles.page}>
      <SEO title={giveaway.title} description={giveaway.description} />
      <header className={styles.header}>
        <ContentWrapper mobileNoPadding className={styles.headerContent}>
          <div className={styles.titleWrapper}>
            <ContentWrapper className={styles.titleContent}>
              <h1 className={styles.heading}>
                {title} <strong>{isDrawing ? 'Drawing' : 'Giveaway'}</strong>
              </h1>
            </ContentWrapper>
          </div>

          <ContentWrapper className={styles.headerBottom}>
            <div className={styles.steps}>
              <ol className={styles.stepsList}>
                <li>Create an Omnia Account</li>
                {isDrawing ? (
                  <>
                    <li>Enter the drawing</li>
                  </>
                ) : (
                  <>
                    <li>Enter your shipping address</li>
                    <li>We'll mail you {description}</li>
                  </>
                )}
              </ol>
            </div>
            {isMobile && (
              <div className={styles.headerImg}>
                <img src={getImgixPath(image, { w: 300 })} alt={title} />
              </div>
            )}
          </ContentWrapper>
        </ContentWrapper>
      </header>

      <ContentWrapper>
        <div className={styles.bottom}>
          <div className={styles.formWrapper}>
            {giveaway.complete ? (
              <h2>This giveaway entry period has ended.</h2>
            ) : (
              <>
                {isLoggedIn ? (
                  <SignupGiveawayForm slug={slug} giveawayProduct={giveaway} />
                ) : (
                  <>
                    {userEmailExists == null ? (
                      <CheckEmailForm
                        onEmailCheck={(emailExists, email) => {
                          setUserEmail(email);
                          setUserEmailExists(emailExists);
                        }}
                      />
                    ) : (
                      <>
                        {userEmailExists ? (
                          <MiniLoginForm initialEmail={userEmail} />
                        ) : (
                          <MiniSignupForm
                            initialEmail={userEmail}
                            giveawayProduct={giveaway}
                            onSubmit={({ species, waterbodies }) => {
                              replaceQueryString({
                                [SignupGiveawayQueryParams.species]: species?.[0],
                                [SignupGiveawayQueryParams.waterbody_name]:
                                  waterbodies?.[0]?.primary_name,
                                [SignupGiveawayQueryParams.waterbody_slug]:
                                  waterbodies?.[0]?.url_slug,
                              });
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>

          <div className={styles.faq}>
            {!isMobile && <img src={getImgixPath(image, { w: 1125 })} alt={title} />}
            {hasVideo && videoContent}
            {faq && <div dangerouslySetInnerHTML={{ __html: faq }} />}
            {isGiveaway && (
              <>
                <h3>When will the shipment arrive?</h3>
                <p>
                  Within 10 days of creating your account. Packages will be shipping USPS First
                  Class. These are going out quickly, so you won't get a shipment confirmation email
                  and there will not be tracking information. (Normal Omnia Fishing orders include
                  confirmation emails and full shipment tracking.)
                </p>
              </>
            )}
            <h3>Who do I contact if I have question?</h3>
            <p>
              You can email us at <a href="mailto:hello@omniafishing.com">hello@omniafishing.com</a>{' '}
              and we'll get right back to you.
            </p>
            <h3>What if I already have an Omnia account?</h3>
            <p>
              You can still enter. Complete the form with your existing Omnia account information
              {isGiveaway && ' and enter your shipping address'}.
            </p>
          </div>
        </div>
        {rules && (
          <div className={styles.rules}>
            <h2>Rules</h2>
            <div dangerouslySetInnerHTML={{ __html: rules }} />
          </div>
        )}
      </ContentWrapper>
    </section>
  );
};
