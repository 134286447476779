import React from 'react';
import { useFrontload } from 'react-frontload';
import Loading from '../../components/loading/loading';
import { apiV1 } from '../../lib/api';
import { SignupGiveaway } from './signup_giveaway';
interface OwnProps {
  slug: string;
}

export const SignupGiveawayContainer = (props: OwnProps) => {
  const { slug } = props;

  const { frontloadMeta, data } = useFrontload(`SignupGiveawayContainer:${slug}`, async () => {
    const result = await apiV1.giveawayProductFetch(slug);
    return { giveaway: result.data.data };
  });

  if (frontloadMeta.pending) {
    return <Loading text="Loading" />;
  }

  return <SignupGiveaway giveaway={data.giveaway} />;
};
