import { UserOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import React from 'react';
import { apiV1 } from '../../lib/api';
import { OmniaButton } from '../omnia_button/omnia_button';

interface CheckEmailFormProps {
  onEmailCheck: (result: boolean, email: string) => void;
}

export const CheckEmailForm = (props: CheckEmailFormProps) => {
  const formFields = {
    email: 'email',
  };

  const handleSubmit = (values: any) => {
    apiV1.emailCheck(values.email).then((response) => {
      props.onEmailCheck(response.data.data as boolean, values.email);
    });
  };

  return (
    <div>
      <h2>Sign in or register to enter</h2>
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item
          validateTrigger={['onBlur']}
          name={formFields.email}
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid email',
            },
            {
              required: true,
              message: 'Please enter your email',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
            type="email"
            size="large"
          />
        </Form.Item>
        <OmniaButton kind="secondary" size="lg" block type="submit">
          Submit
        </OmniaButton>
      </Form>
    </div>
  );
};
